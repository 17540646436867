import request from "./request";
//路由拦截和路由守卫相关接口写在这里
export function afterLogout(id) {
  if (!id) {
    id = ''
  }
  return request().get("/api/iam/v1/a/odic/login?pflow=" + id);
}

export function logout(params) {
  if (!params) {
    params = ''
  }
  return request().get("/api/iam/v1/a/odic/logout?pflow=" + params);
}


