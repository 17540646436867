import { getDetails } from "@/api/task/api.js";

const taskModule = {
    namespaced: true,//开启命名空间 
    state: () => ({
        formState: {},//基础信息+采集项数据
        customerData: {},//关联的选择目标客户数据
        cbUrl: null,
        //获取详情并更新
        getNewformState(taskInfoCode, change = false) {
            return new Promise((resolve, reject) => {
                getDetails(taskInfoCode).then((res) => {
                    if (res.data.success) {
                        if (change) {
                            this.formState = res.data.data
                        }
                        resolve(res.data.data)
                    } else {
                        resolve(false)
                    }
                })
            })
        },
        checkPublishPort: (code) => {
            switch (code) {
                case "CTT000ACTIVITY":
                    return "MIKA"
                case "CTT000EXPANDTASK":
                    return "PXTZT"
                case "CTT000ADDSTORE":
                    return "PXTZT"
                default:
                    return "PXTZT"
            }
        },
        checkPerformPort: (code) => {
            switch (code) {
                case "CTT000ACTIVITY":
                    return "UDEFINED"
                case "CTT000EXPANDTASK":
                    return "PXTZT"
                case "CTT000ADDSTORE":
                    return "PXTZT"
                default:
                    return "PXTZT"
            }
        },
        vueProblemDrawer:null,
    }),
    mutations: {
        formState(state, data) {
            state.formState = data;
        },
        customerData(state, data) {
            state.customerData = data;
        },
        cbUrl(state, data) {
            if (data) {
                state.cbUrl = data;
            }
        },
        vueProblemDrawer(state, data) {
            state.vueProblemDrawer = data;
        },
    },
    actions: {},
    getters: {}
}
export default taskModule