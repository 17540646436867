import { createApp } from "vue";
import App from "./App.vue";
import Antd from 'ant-design-vue';
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.less";
import "@/common/css/font.scss";
import '@/assets/iam-iconfont/iconfont.css'
import { message, Modal } from 'ant-design-vue';

// import axios from "axios";
//异步注入百度地图的AK  然后再加载组件   暂时先不用
// axios.get("/api/base/v1/areaselect/province/find").then(() => {
//   var script = document.createElement("script");
//   script.setAttribute(
//     "src",
//     "https://api.map.baidu.com/getscript?v=3.0&ak=woXDNV17Y1C7FNg9nnqaPObEymOB9adG&services=&t=20220113143055"
//   );
//   document.getElementsByTagName("head")[0].appendChild(script);
//   createApp(App).use(store).use(router).mount("#app");
// });
// App.prototype.$message = message;
const app: any = createApp(App);
app.config.globalProperties.$message = message;// (this as any).$message.success('111')
app.config.globalProperties.$modal = Modal;// (this as any).$message.success('111')
// (window as any).$message = message;// $message.success('111')
app.use(store).use(router).use(Antd).mount("#app");

