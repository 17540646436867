import request from "@/utils/request.js";
import request2 from "@/utils/requestNoLoading.js";
let baseUrl;
function checkBaseUrl() {
  baseUrl = "";
}
checkBaseUrl();
//获取详情
export function getDetails(taskInfoCode) {
  return request().get(`/api/custom/v1/pxt/task/getTask`, {
    params: { taskInfoCode: taskInfoCode },
  });
}
//新建任务
export function addMode(data) {
  return request().post(`/api/custom/v1/pxt/task/add`, data);
}
//编辑任务 步骤1
export function updateMode1(data) {
  return request().post(`/api/custom/v1/pxt/task/update`, data);
}
//编辑任务 步骤3
export function updateMode(data) {
  return request().post(`/api/custom/v1/pxt/task/updateTaskPackage`, data);
}
//复制模板 快速创建
export function copyMode(taskInfoCode) {
  return request().post(
    `/api/custom/v1/pxt/task/copyTask?taskInfoCode=${taskInfoCode}`
  );
}
//步骤2保存
export function addDetails(data) {
  return request().post(`/api/custom/v1/pxt/task/addDetails`, data);
}
//步骤2 弹窗 获取全部终端列表
export function terminalList(params) {
  return request().get(`/api/custom/v1/pxt/task/terminalList`, {
    params: params,
  });
}
//获取全部渠道
export function getchannelOption() {
  return request2().get(`/api/custom/v1/pxt/task/channelList`);
}
//获取全部活跃度
export function getactivityOption() {
  return request2().get(`/api/custom/v1/pxt/task/activityList`);
}
//获取全部标签
export function getcusLabIdOption() {
  return request2().get(`/api/custom/v1/pxt/task/labelList`);
}
// 执行规则配置
export function taskConfig(data) {
  return request().post(`/api/custom/v1/pxt/task/xxx`, data);
}
// 发布任务
export function taskRelease(data) {
  return request().post(`/api/custom/v1/pxt/task/taskRelease`, data);
}
