import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Main from "../views/Main.vue"
import store from "../store";
import { afterLogout } from "@/utils/requestFunction.js";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "/",
    component: Main,
    // redirect: "/main/xxx",
  },
  {
    path: "/main",
    name: "任务定制化",
    component: Main,
    meta: { hidden: false, title: "任务定制化", },
    children: [
      {
        path: "/taskApp",
        name: "嵌入样式",
        component: () => import("@/views/test.vue"),
        meta: {
          hidden: false,
          title: "嵌入样式",
          key: '',
        }
      }
    ],
  },
  //单独app嵌入使用页面
  {
    path: "/taskApp/pxt/created/:id",
    component: () => import("@/views/pxt/task/Created.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (store.state.isLogin === false) {
//     afterLogout().then((res) => {
//       if (res.data.success) {
//         store.commit("changeisLogin", true);
//         next();
//       }
//     });
//   } else {
//     next();
//   }
// });
export default router;
