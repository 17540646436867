import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45fa5ede"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "business" }
const _hoisted_2 = { class: "userNameforreload" }
const _hoisted_3 = { style: {"font-weight":"900"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkOutlined = _resolveComponent("LinkOutlined")!
  const _component_Item = _resolveComponent("Item")!
  const _component_Sub_menu = _resolveComponent("Sub-menu")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Sider = _resolveComponent("Sider")!
  const _component_Content = _resolveComponent("Content")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_ctx.showSider)
    ? (_openBlock(), _createBlock(_component_Layout, {
        key: 0,
        class: "Sider_layoutComponents",
        id: "Sider"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Sider, {
            style: { background: '#fff' },
            class: "sider"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(this.$store.state.userInfo.tenant), 1)
              ]),
              _createVNode(_component_Menu, {
                id: "dddddd",
                style: _normalizeStyle({ width: '200px', 'min-height': _ctx.menuHeight }),
                openKeys: _ctx.openKeys,
                "onUpdate:openKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openKeys) = $event)),
                selectedKeys: [_ctx.$route.path],
                mode: "inline",
                class: "myMenu",
                openChange: _ctx.openChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: item.path
                    }, [
                      (!item.children)
                        ? (_openBlock(), _createBlock(_component_Item, {
                            key: item.path,
                            onClick: ($event: any) => (_ctx.handleClick(item)),
                            class: "itemBox"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_3, [
                                _createTextVNode(_toDisplayString(item.name), 1),
                                (item.path != '/main/qualifications')
                                  ? (_openBlock(), _createBlock(_component_LinkOutlined, { key: 0 }))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        : (_openBlock(), _createBlock(_component_Sub_menu, {
                            onTitleClick: ($event: any) => (_ctx.titleClick(item)),
                            key: item.path,
                            class: _normalizeClass(["SubmenuOne", !item.children ? 'show' : '']),
                            title: item.meta.title,
                            style: {"font-weight":"600"}
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (data) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: data.path
                                }, [
                                  (!data.children)
                                    ? (_openBlock(), _createBlock(_component_Item, {
                                        key: data.path,
                                        onClick: ($event: any) => (_ctx.handleClick(data)),
                                        class: _normalizeClass(
                      data.meta.hidden === true
                        ? 'layout_sideBar_hiddenBox'
                        : ''
                    )
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(data.meta.title), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick", "class"]))
                                    : (_openBlock(), _createBlock(_component_Sub_menu, {
                                        onTitleClick: ($event: any) => (_ctx.titleClick(data)),
                                        key: data.path,
                                        class: _normalizeClass(!data.children ? 'show' : ''),
                                        title: data.meta.title
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.children, (i) => {
                                            return (_openBlock(), _createBlock(_component_Item, {
                                              key: i.path,
                                              onClick: ($event: any) => (_ctx.handleClick(i)),
                                              class: _normalizeClass(["dataItem", 
                          i.meta.hidden === true
                            ? 'layout_sideBar_hiddenBox'
                            : ''
                        ])
                                            }, {
                                              default: _withCtx(() => [
                                                _createElementVNode("div", null, _toDisplayString(i.meta.title), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick", "class"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1032, ["onTitleClick", "class", "title"]))
                                ], 64))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["onTitleClick", "class", "title"]))
                    ], 64))
                  }), 128))
                ]),
                _: 1
              }, 8, ["style", "openKeys", "selectedKeys", "openChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_Content, {
            breadcrumb: [_ctx.$route.matched]
          }, null, 8, ["breadcrumb"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}