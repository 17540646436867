const configModule = {
    namespaced: true,//开启命名空间 
    state: () => ({
        problemNum: 20,  //任务模板配置问题最大数量   页面调用(this as any).$store.state.config.problemNum
        optionNum: 10,//选择题最大数量 
        letter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T"],
        taskConfig: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],//执行配置最大次数
        taskType: {
            "CTT000ACTIVITY": '活动推广',
            "CTT000EXPANDTASK": '拓店任务',
            "CTT000ADDSTORE": '新增店铺',
            "CTT000ORDINARY": '普通任务',
        },
    }),
    mutations: {
        //修改模块中的状态   页面调用(this as any).$store.commit('config/setTest',3)
        // setTest(state, test) {
        //     state.test = test;
        // }
    },
    actions: {},
    getters: {}
}
export default configModule