
import { defineComponent } from "vue";
import {
  Layout,
  Avatar,
  Popover,
  Dropdown,
  Menu,
  Button,
  Input,
  Col,
  Row,
  Divider,
  List,
} from "ant-design-vue";
import {
  UserOutlined,
  UnorderedListOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons-vue";
import { afterLogout, logout } from "@/utils/requestFunction";
const { Item: ItemList } = List;
const { Header } = Layout;
const { Item } = Menu;
export default defineComponent({
  components: {
    Layout,
    Header,
    aAvatar: Avatar,
    UserOutlined,
    Dropdown,
    aPopover: Popover,
    Item,
    Menu,
    UnorderedListOutlined,
    SafetyCertificateOutlined,
    aButton: Button,
    aInput: Input,
    aCol: Col,
    aRow: Row,
    aDivider: Divider,
    aList: List,
    aListItem: ItemList,
  },
  data() {
    return {};
  },
  methods: {
    loginOut() {
      logout().then((res: any) => {
        afterLogout(res.data.data.pflow);
      });
    },
    // 前往认证
    toCertUrl(key: any) {
      switch (key) {
        case 0:
          window.open((this as any).$store.state.userInfo.uri_user_auth);
          break;
        case 1:
          window.open((this as any).$store.state.userInfo.uri_user);
          break;
        case 2:
          window.open((this as any).$store.state.userInfo.uri_safe);
          break;
        case 3:
          window.open((this as any).$store.state.userInfo.uri_auth);
          break;
        default:
          break;
      }
    },
    // 前往应用看板
    toDashBoard() {
      window.open((this as any).$store.state.userInfo.uri_kanban, "_self");
    },
    //点击消息中心中的消息
    toPath(flag: number, item: any) {
      if (flag === 0) {
        // util.addressJumpToNewWindow(this.userInfo.msg_all_uri);
        window.open((this as any).$store.state.userInfo.msg_all_uri, "_self");
      } else if (flag === 1) {
        // util.addressJumpToNewWindow(this.userInfo.msg_pre + "id=" + item.msgId);
        window.open(
          (this as any).$store.state.userInfo.msg_pre + "id=" + item.msgId,
          "_self"
        );
      }
    },
  },
});
